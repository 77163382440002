/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import LocaleContext from '@context/LocaleContext'
import Header from "@components/Header"
import Footer from "@components/Footer"
import cn from "classnames"
import "./styles"

const Layout = ({ headerFadeIn, delay, children }) => {
  const { code } = useContext(LocaleContext)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <div className={cn('site-container', { 'is-jp': code === 'jp' })}>
      <Header
        siteTitle={data.site.siteMetadata.title}
        fadeIn={headerFadeIn}
        delay={delay}
      />
      <main>
        {children}
      </main>
      <Footer />
    </div>
  )
}

Layout.propTypes = {
  headerFadeIn: PropTypes.bool,
  delay: PropTypes.number,
  children: PropTypes.node.isRequired,
}

Layout.defaultProps = {
  headerFadeIn: false,
  delay: 0,
}

export default Layout
