import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'
import { Link as GatsbyLink } from 'gatsby'
import Link from '@utility/Link'
import MenuPanel from '@components/MenuPanel'
import { getPathWithoutLocale } from '@helpers/url'
import cn from 'classnames'
import styles from './styles'
import LocaleContext from '@context/LocaleContext'

const Header = ({
  siteTitle,
  fadeIn,
  delay,
}) => {
  const location = useLocation()
  const path = getPathWithoutLocale(location.pathname)
  const [menuOpen, setMenuOpen] = useState(false)
  const [active, setActive] = useState(false)
  const [scrollDown, setScrollDown] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => { setActive(true) }, delay)
    return () => clearTimeout(timer)
  }, [delay])

  useScrollPosition(({prevPos, currPos}) => {
    prevPos.y > 0 ? setScrollDown(false) : setScrollDown(prevPos.y > currPos.y)
  }, [scrollDown], null, false, 150)

  return (
    <LocaleContext.Consumer>
      { locale => (
        <div className={styles.navigation}>
          <header
            className={cn(
              styles.header,
              {[styles.transparent]: fadeIn},
              {[styles.down]: scrollDown},
              {[styles.active]: active})}
          >
            <div className={cn("wrapper", styles.wrapper)}>
              <div className="row">
                <div className="col-2">
                  <ul className={styles.switcher}>
                    <li className={cn({[styles.active]: locale.code === 'jp'})}><GatsbyLink to={`${path}`} onClick={locale.updateLocale}>JP</GatsbyLink></li>
                    <li className={cn({[styles.active]: locale.code === 'en'})}><GatsbyLink to={`/en${path}`} onClick={locale.updateLocale}>EN</GatsbyLink></li>
                  </ul>
                </div>
                <div className="col-8 flex items-center">
                  <h1 className={styles.title}>
                    <Link to="/" className={styles.link}>{siteTitle}</Link>
                  </h1>
                </div>
                <div className="col-2">
                  <div
                    className={cn(styles.menuBtn, [{[styles.open]: menuOpen}])}
                    onClick={() => setMenuOpen(!menuOpen)}
                    onKeyPress={() => setMenuOpen(!menuOpen)}
                    role="button"
                    tabIndex="0"
                  >
                    <div className={styles.container}>
                      <span /><span />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
          <MenuPanel open={menuOpen} />
        </div>
      )}
    </LocaleContext.Consumer>
  );

}

Header.propTypes = {
  siteTitle: PropTypes.string,
  fadeIn: PropTypes.bool,
  delay: PropTypes.number,
}

Header.defaultProps = {
  siteTitle: ``,
  fadeIn: false,
  delay: 0,
}

export default Header
