import React, { useContext } from 'react'
import PropTypes from 'prop-types';
import LocaleContext from '@context/LocaleContext';
import { Link as GatsbyLink } from 'gatsby'
import cn from 'classnames';
import styles from './styles.module.scss';

const Link = ({
  children,
  to,
  className,
  internalExternal,
  styled,
  ...other
}) => {
  const localeContext = useContext(LocaleContext);

  const internal = () => {
    if (internalExternal) {
      if (to._type === 'externalLink') {
        return false;
      }
      return true;
    }
    return /^[/#](?!\/)/.test(to); // hash or querystring
  };

  const renderUrl = () => {
    if (internalExternal) {
      return to._type === 'externalLink' || to._type === 'relativeLink'
        ? to.url
        : `/${to.page?.slug.current}`;
    }
    return to;
  };

  // Use Gatsby link if internal link
  if (internal()) {
    return (
      <GatsbyLink to={localeContext.code === 'en' ? `/en${renderUrl()}` : renderUrl()} className={cn(className, {[styles.link]: styled})} {...other}>
        {children}
      </GatsbyLink>
    )
  }

  // User anchor if external link
  return (
    <a href={renderUrl()} target="_blank" rel="noreferrer" className={cn(className, {[styles.link]: styled})} {...other}>
      {children}
    </a>
  );
}

Link.propTypes = {
  className: PropTypes.string,
  internalExternal: PropTypes.bool,
  styled: PropTypes.bool,
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
};

Link.defaultProps = {
  className: '',
  internalExternal: false,
  styled: false,
};

export default Link;
