import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import cn from 'classnames';
import { Transition } from 'react-transition-group'
import LocaleContext from '@context/LocaleContext'
import Link from '@utility/Link';
import styles from './styles'

const MenuPanel = ({ open }) => {
  const { code } = useContext(LocaleContext)
  const data = useStaticQuery(graphql`
    {
      sanityMenu {
        links {
          ... on SanityExternalLink {
            _key
            _type
            url
            label {
              en
              jp
            }
          }
          ... on SanityInternalLink {
            _key
            _type
            label {
              en
              jp
            }
            page {
              ... on SanityPage {
                slug {
                  current
                }
              }
              ... on SanityTattooCategory {
                slug {
                  current
                }
              }
            }
          }
        }
      }
      sanityHomePage {
        title {
          en
          jp
        }
      }
    }
  `)

  return (
    <Transition
      in={open}
      timeout={1500}
    >
      {state => (
        data.sanityMenu.links.length &&
          <div className={cn(styles.menu, state)}>
            <ul>
              <li className="md:hidden">
                <Link to="/">{code === 'jp' ? data.sanityHomePage.title.jp : data.sanityHomePage.title.en}</Link>
              </li>
              {data.sanityMenu.links.map(link => (
                <li key={link._key}>
                  <Link to={link} internalExternal>
                    {code === 'jp' ? link.label.jp : link.label.en}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
      )}
    </Transition>
  )
}

MenuPanel.propTypes = {
  open: PropTypes.bool,
}

MenuPanel.defaultProps = {
  open: false,
}

export default MenuPanel
