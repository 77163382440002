import React, { useContext } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import LocaleContext from '@context/LocaleContext'
import Link from '@utility/Link'
import style from './styles'

const Footer = () => {
  const { code } = useContext(LocaleContext)
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
      sanityMenu {
        links {
          ... on SanityExternalLink {
            _key
            _type
            url
            label {
              en
              jp
            }
          }
          ... on SanityInternalLink {
            _key
            _type
            label {
              en
              jp
            }
            page {
              ... on SanityPage {
                slug {
                  current
                }
              }
              ... on SanityTattooCategory {
                slug {
                  current
                }
              }
            }
          }
        }
      }
      sanityFooter {
        map {
          _type
          label {
            en
            jp
          }
          url
        }
        phone
        postalCode
        address {
          en
          jp
        }
        openHours
        closed {
          en
          jp
        }
        socials {
          links {
            _key
            _type
            label {
              en
              jp
            }
            url
          }
        }
      }
    }
  `)

  return (
    <footer className={style.footer}>
      <div className="wrapper">
        <div className="row">
          <div className={`${style.left} col-12 md:col-8 lg:col-9`}>
            <div>
              <p className="mb-8">
                〒{data.sanityFooter.postalCode}<br />
                {code === 'jp' ? data.sanityFooter.address.jp : data.sanityFooter.address.en }<br />
                <a href={`tel:${data.sanityFooter.phone}`}>{data.sanityFooter.phone}</a>
              </p>
              {data.sanityFooter.map.url &&
                <Link
                  to={data.sanityFooter.map}
                  internalExternal
                  styled
                >
                  {(code === 'jp' ? data.sanityFooter.map.label.jp : data.sanityFooter.map.label.en) || 'Google Map'}
                </Link>
              }
            </div>
            <div>
              {data.sanityFooter.openHours && <>Open: {data.sanityFooter.openHours}<br /></>}
              <span className="text-red">{code === 'jp' ? "ご予約制となっております。" : "Reservation Only" }</span><br />
              {data.sanityFooter.closed && (code === 'jp' ? data.sanityFooter.closed.jp : data.sanityFooter.closed.en )}
            </div>

            {data.sanityMenu.links.length &&
              <ul>
                {data.sanityMenu.links.map(link => (
                  <li className={style.link} key={link._key}>
                    <Link to={link} internalExternal>
                      {code === 'jp' ? link.label.jp : link.label.en}
                    </Link>
                  </li>
                ))}
              </ul>
            }

            {!!data.sanityFooter.socials.links.length && (
              <ul>
                { data.sanityFooter.socials.links.map(link => (
                  <li className={style.link} key={link._key}>
                    <Link to={link} internalExternal>{ code === 'jp' ? link.label.jp : link.label.en }</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
          <div className={`${style.right} col-12 md:col-4 lg:col-3`}>
            <small className={style.copyright}>&#169; {new Date().getFullYear()} {data.site.siteMetadata.title}</small>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
